import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  },
  ASSIGN_DATA_DETAIL(state, data) {
    state.data_detail = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get(`operational/job_orders`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store(store, payload) {
    try {
      let { data } = await axios.post(`operational/job_orders`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store_details(store, payload) {
    try {
      let { data } = await axios.post('operational/job_orders/' + payload.job_order_id + '/details', payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update_details(store, payload) {
    try {
      let { data } = await axios.put('operational/job_orders/' + payload.job_order_id + '/details/' + payload.id, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show_details({commit}, payload) {
    try {
      let {data}  = await axios.get('operational/job_orders/' + payload.job_order_id + '/details/' + payload.id, payload)
      commit("ASSIGN_DATA_DETAIL", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show({commit}, id) {
    try {
      let { data } = await axios.get(`operational/job_orders/${id}`)
      commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async get_commodities(store, id) {
    try {
      let { data } = await axios.get(`operational/job_orders/${id}/commodities`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async get_costs(store, id) {
    try {
      let { data } = await axios.get(`operational/job_orders/${id}/costs`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show_costs({commit}, id) {
    try {
      let { data } = await axios.get(`operational/job_orders/show_costs/${id}`)
      commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store_costs(store, params) {
    try {
      let { data } = await axios.post(`operational/job_orders/store_costs`, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update_costs(store, params) {
    try {
      let { data } = await axios.put(`operational/job_orders/update_costs/` + params.id, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async approve_costs(store, id) {
    try {
      let { data } = await axios.post(`operational/job_orders/approve_costs/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async delete_costs(store, id) {
    try {
      let { data } = await axios.delete(`operational/job_orders/delete_costs/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async delete_details(store, id) {
    try {
      let { data } = await axios.delete(`operational/job_orders/delete_details/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async detail_show(store, id) {
    try {
      let { data } = await axios.get(`operational/job_order_details/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update(store, payload) {
    try {
      let { data } = await axios.put(`operational/job_orders/${payload.id}`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async destroy(store, id) {
    try {
      let { data } = await axios.delete(`operational/job_orders/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}