import axios from '@/axios'
const state = {
  auth: {},
  access_token: null,
  refresh_token: null,
  token_type: 'Bearer',
  grant_type: 'password',
  expires_in: 0,
  client_id: `1`,
  client_secret: `9eL4KMPYexuejKNqjcIuEBFFw25M4KsyOcdE90FC`,
}
const mutations = {
  AUTHENTICATE(state, data) {
    state.access_token = data.access_token
    state.refresh_token = data.refresh_token,
    state.token_type = data.token_type,
    state.expires_in = data.expires_in
  },
  UNAUTHENTICATE(state) {
    state.auth = {}
  },
  ACCESS_USER(state, data) {
    state.data = data
  }
}
const actions = {
  login({ commit, rootState, state }, payload) {
    return new Promise(async (resolve, reject) => {
      try {
        let data = {
          client_id : state.client_id,
          client_secret : state.client_secret,
          grant_type : state.grant_type
        }
        Object.assign(data,payload)
        let resp = await axios.post(`user/login`, data)
        // eslint-disable-next-line no-undef
        $cookies.set(rootState.cookieBearerKey, `Bearer ${resp.data.data.access_token}`, '8h')
        commit('AUTHENTICATE', resp.data.data)
        resolve(resp)
      } catch (error) {
        reject(error.response)
      }      
    })
  },
  async confirmation(store, payload) {
    try {
      let data  = await axios.put(`user/confirmation`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async change(store, payload) {
    try {
      let data  = await axios.put('user/change/' + payload.slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  logout({ commit, rootState }) {
    return new Promise(async (resolve, reject) => {
      try {
        let resp = await axios.post(`user/logout`)
        // eslint-disable-next-line no-undef
        localStorage.removeItem('userInfo')
        $cookies.remove(rootState.cookieBearerKey)
        commit('AUTH_USER', resp.data)
        resolve(resp)
      } catch (error) {
        reject(error.response)
      }
    })    
  },
  async getUser({ commit }) {
    try {
      let { data } = await axios.get(`user/get_user`)
      commit('ACCESS_USER', data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  mutations,
  actions
}