import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  a_rows: {
    data: [],
    last_page: 1
  },  
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_ADDRESS_LIST(state, data) {
    state.a_rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get(`contact/contacts`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async address_list({ commit }, payload) {
    try {
      let { data } = await axios.get(`contact/contacts/address_list`, {params: payload})
      commit("ASSIGN_ADDRESS_LIST", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store(store, payload) {
    try {
      let { data } = await axios.post(`contact/contacts`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeFromExistingContact(store, payload) {
    try {
      let { data } = await axios.post(`contact/contacts/store_from_existing_contact`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update(store, payload) {
    try {
      let { data } = await axios.put(`contact/contacts/${payload.id}`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show(store, id) {
    try {
      let { data } = await axios.get(`contact/contacts/${id}`)
      // commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async destroy(store, id) {
    try {
      let { data } = await axios.delete(`contact/contacts/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async destroyAddressList(store, payload) {
    try {
      let { data } = await axios.delete(`contact/contacts/${payload.contact_id}/${payload.address_id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async getAddressNameByCoordinate({commit}, payload) {
    try {
      let { data } = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${payload.lat}&lon=${payload.lng}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async getAddressName({commit}, payload) {
    try {
      let { data } = await axios.get(`https://nominatim.openstreetmap.org/search?format=jsonv2&q=${payload}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}