import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  },
  ASSIGN_DATA_DETAIL(state, data) {
    state.data_detail = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get('operational/job_orders/' + payload.job_order_id + '/files', {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store(store, {payload, job_order_id}) {
    try {
      let { data } = await axios.post('operational/job_orders/' + job_order_id + '/files', payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show({commit}, payload) {
    try {
      let { data } = await axios.get('operational/job_orders/' + payload.job_order_id + '/files/' + payload.id)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update(store, {payload, job_order_id, id}) {
    try {
      let { data } = await axios.post(`operational/job_orders/${job_order_id}/files/${id}`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async destroy(store, payload) {
    try {
      let { data } = await axios.delete('operational/job_orders/' + payload.job_order_id + '/files/' + payload.id)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}