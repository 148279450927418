/*=========================================================================================
  File Name: store.js
  Description: Vuex store
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"
/*------------ Modules ------------*/
import auth from './modules/auth'
import dashboard from './modules/dashboard/dashboard'
// Setting
import master from './modules/setting/master'
import companies from './modules/setting/companies'
import users from './modules/setting/users'
import areas from './modules/setting/areas'
import application from './modules/setting/application'
import commodities from './modules/setting/commodities'
import units from './modules/setting/units'
import container_types from './modules/setting/container_types'
import vehicle_types from './modules/setting/vehicle_types'
import costs from './modules/setting/costs'
import routes from './modules/setting/routes'
import vehicles from './modules/setting/vehicles'
import car_factories from './modules/setting/car_factories'
import car_colors from './modules/setting/car_colors'
import interiors from './modules/setting/interiors'
import car_types from './modules/setting/car_types'
import car_spareparts from './modules/setting/car_spareparts'
import transaction_types from './modules/setting/transaction_types'
import company_numberings from './modules/setting/company_numberings'
import roles from './modules/setting/roles'
import taxes from './modules/setting/taxes'
// Contact
import contacts from './modules/contact/contacts'
import price_lists from './modules/marketing/price_lists'
import contracts from './modules/marketing/contracts'
// Operational
import job_orders from './modules/operational/job_orders'
import job_order_files from './modules/operational/job_order_files'
import job_order_details from './modules/operational/job_order_details'
import manifests from './modules/operational/manifests'
import delivery_orders from './modules/operational/delivery_orders'
import invoices from './modules/operational/invoices'
import invoice_details from './modules/operational/invoice_details'
import receivables from './modules/operational/receivables'
import payables from './modules/operational/payables'
import bills from './modules/operational/bills'
import debts from './modules/operational/debts'
import operational_reports from './modules/operational/operational_reports'
import map_shipments from './modules/operational/map_shipments'
import sparepart_shipments from './modules/operational/report/sparepart_shipments'
import drivers from './modules/operational/report/drivers'
import driver_performances from './modules/operational/report/driver_performance'
// Driver
import driver_absences from './modules/driver/driver_absences'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
      auth: auth,
      dashboard: dashboard,
      /* SETTING */
      application: application,
      areas: areas,
      master: master,
      companies: companies,
      users: users,
      commodities: commodities,
      units: units,
      container_types: container_types,
      vehicle_types: vehicle_types,
      costs: costs,
      routes: routes,
      vehicles: vehicles,
      car_factories: car_factories,
      car_colors: car_colors,
      interiors: interiors,
      car_types: car_types,
      car_spareparts: car_spareparts,
      transaction_types: transaction_types,
      company_numberings: company_numberings,
      roles: roles,
      taxes: taxes,

      /* MARKETING */
      price_lists: price_lists,
      contracts: contracts,
      /* CONTACTS */
      contacts: contacts,
      /* OPERATIONAL */
      job_orders: job_orders,
      job_order_files: job_order_files,
      job_order_details: job_order_details,
      manifests: manifests,
      delivery_orders: delivery_orders,
      invoices: invoices,
      invoice_details: invoice_details,
      receivables: receivables,
      payables: payables,
      bills: bills,
      debts: debts,
      map_shipments: map_shipments,
      sparepart_shipments: sparepart_shipments,
      drivers: drivers,
      operational_reports: operational_reports,
      driver_performances: driver_performances,
      /* DRIVER */
      driver_absences: driver_absences,
    },
    getters,
    mutations,
    state,
    actions,
    strict: process.env.NODE_ENV !== 'production'
})
