import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = datas
  }
}
const actions = {
  async showTaxes({commit}, payload = {}) {
    try {
      let invoice_id = payload.invoice_id
      let invoice_detail_id = payload.invoice_detail_id
      let { data } = await axios.get(`operational/invoices/${invoice_id}/detail/${invoice_detail_id}/taxes`)
      // commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}