export default [
  {
    path: '/setting',
    redirect: {name: 'companies'}
  },
  {
    path: '/setting/application',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'application',
        component: () => import('@pages/setting/application/Index.vue'),
        meta: {
          auth: true,
          parent: 'application',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Application', active: true }
          ],
        }
      }
    ]
  },
  // Area
  {
    path: '/setting/areas',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'areas',
        component: () => import('@pages/setting/areas/Index.vue'),
        meta: {
          auth: true,
          parent: 'areas',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Area', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'areas-create',
        component: () => import('@pages/setting/areas/Create.vue'),
        meta: {
          auth: true,
          parent: 'areas',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Area', url: { name: 'areas' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'areas-show',
        component: () => import('@pages/setting/areas/Create.vue'),
        meta: {
          auth: true,
          parent: 'areas',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Area', url: { name: 'areas' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  // Taxes
  {
    path: '/setting/taxes',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'taxes',
        component: () => import('@pages/setting/taxes/Index.vue'),
        meta: {
          auth: true,
          parent: 'taxes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Taxes & VAT', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'taxes-create',
        component: () => import('@pages/setting/taxes/Create.vue'),
        meta: {
          auth: true,
          parent: 'taxes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Taxes & VAT', url: { name: 'taxes' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'taxes-show',
        component: () => import('@pages/setting/taxes/Create.vue'),
        meta: {
          auth: true,
          parent: 'taxes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Taxes & VAT', url: { name: 'taxes' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/setting/companies',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'companies',
        component: () => import('@pages/setting/companies/Index.vue'),
        meta: {
          auth: true,
          parent: 'companies',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'companies-create',
        component: () => import('@pages/setting/companies/Create.vue'),
        meta: {
          auth: true,
          parent: 'companies',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'companies-edit',
        component: () => import('@pages/setting/companies/Create.vue'),
        meta: {
          auth: true,
          parent: 'companies',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'companies-show',
        component: () => import('@pages/setting/companies/Show.vue'),
        meta: {
          auth: true,
          parent: 'companies',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* User Managements */
  {
    path: '/setting/users',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'users',
        component: () => import('@pages/setting/users/Index.vue'),
        meta: {
          auth: true,
          parent: 'users',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Users', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'users-create',
        component: () => import('@pages/setting/users/Create.vue'),
        meta: {
          auth: true,
          parent: 'users',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Users', url: { name: 'users' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'users-show',
        component: () => import('@pages/setting/users/Show.vue'),
        meta: {
          auth: true,
          parent: 'users',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Users', url: { name: 'users' } },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'users-edit',
        component: () => import('@pages/setting/users/Create.vue'),
        meta: {
          auth: true,
          parent: 'users',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Users', url: { name: 'users' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },

  /* Commodities */
  {
    path: '/setting/commodities',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'commodities',
        component: () => import('@pages/setting/commodities/Index.vue'),
        meta: {
          auth: true,
          parent: 'commodities',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Commodities', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'commodities-create',
        component: () => import('@pages/setting/commodities/Create.vue'),
        meta: {
          auth: true,
          parent: 'commodities',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Commodities', url: { name: 'commodities' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'commodities-show',
        component: () => import('@pages/setting/commodities/Create.vue'),
        meta: {
          auth: true,
          parent: 'commodities',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Commodities', url: { name: 'commodities' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Units */
  {
    path: '/setting/units',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'units',
        component: () => import('@pages/setting/units/Index.vue'),
        meta: {
          auth: true,
          parent: 'units',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Units', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'units-create',
        component: () => import('@pages/setting/units/Create.vue'),
        meta: {
          auth: true,
          parent: 'units',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Units', url: { name: 'units' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'units-show',
        component: () => import('@pages/setting/units/Create.vue'),
        meta: {
          auth: true,
          parent: 'units',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Units', url: { name: 'units' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Container */
  {
    path: '/setting/container_types',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'container_types',
        component: () => import('@pages/setting/container_types/Index.vue'),
        meta: {
          auth: true,
          parent: 'container_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Container Type', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'container_types-create',
        component: () => import('@pages/setting/container_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'container_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Container Type', url: { name: 'container_types' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'container_types-show',
        component: () => import('@pages/setting/container_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'container_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Container Type', url: { name: 'container_types' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Vehicle Types */
  {
    path: '/setting/vehicle_types',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'vehicle_types',
        component: () => import('@pages/setting/vehicle_types/Index.vue'),
        meta: {
          auth: true,
          parent: 'vehicle_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicle Type', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicle_types-create',
        component: () => import('@pages/setting/vehicle_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'vehicle_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicle Type', url: { name: 'vehicle_types' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'vehicle_types-show',
        component: () => import('@pages/setting/vehicle_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'vehicle_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicle Type', url: { name: 'vehicle_types' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Costs / Jenis biaya */
  {
    path: '/setting/costs',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'costs',
        component: () => import('@pages/setting/costs/Index.vue'),
        meta: {
          auth: true,
          parent: 'costs',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Costs', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'costs-create',
        component: () => import('@pages/setting/costs/Create.vue'),
        meta: {
          auth: true,
          parent: 'costs',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Costs', url: { name: 'costs' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'costs-show',
        component: () => import('@pages/setting/costs/Create.vue'),
        meta: {
          auth: true,
          parent: 'costs',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Costs', url: { name: 'costs' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Costs / Jenis biaya */
  {
    path: '/setting/routes',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'routes',
        component: () => import('@pages/setting/routes/Index.vue'),
        meta: {
          auth: true,
          parent: 'routes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Routes', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'routes-create',
        component: () => import('@pages/setting/routes/Create.vue'),
        meta: {
          auth: true,
          parent: 'routes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Routes', url: { name: 'routes' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'routes-edit',
        component: () => import('@pages/setting/routes/Create.vue'),
        meta: {
          auth: true,
          parent: 'routes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Routes', url: { name: 'routes' } },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'routes-show',
        component: () => import('@pages/setting/routes/Show.vue'),
        meta: {
          auth: true,
          parent: 'routes',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Routes', url: { name: 'routes' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Vehicles */
  {
    path: '/setting/vehicles',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'vehicles',
        component: () => import('@pages/setting/vehicles/Index.vue'),
        meta: {
          auth: true,
          parent: 'vehicles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicles', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'vehicles-show',
        component: () => import('@pages/setting/vehicles/Show.vue'),
        meta: {
          auth: true,
          parent: 'vehicles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicles', url: { name: 'vehicles' } },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'vehicles-create',
        component: () => import('@pages/setting/vehicles/Create.vue'),
        meta: {
          auth: true,
          parent: 'vehicles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicles', url: { name: 'vehicles' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'vehicles-edit',
        component: () => import('@pages/setting/vehicles/Create.vue'),
        meta: {
          auth: true,
          parent: 'vehicles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Vehicles', url: { name: 'vehicles' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/setting/items_product',
    redirect: {name: 'car_factories'}
  },
  /* Car Factories */
  {
    path: '/setting/car_factories',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'car_factories',
        component: () => import('@pages/setting/car_factories/Index.vue'),
        meta: {
          auth: true,
          parent: 'car_factories',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Items Product'},
            { title: 'Car Factories', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'car_factories-create',
        component: () => import('@pages/setting/car_factories/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_factories',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Factories', url: { name: 'car_factories' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'car_factories-show',
        component: () => import('@pages/setting/car_factories/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_factories',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Factories', url: { name: 'car_factories' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Car Color */
  {
    path: '/setting/car_colors',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'car_colors',
        component: () => import('@pages/setting/car_colors/Index.vue'),
        meta: {
          auth: true,
          parent: 'car_colors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Items Product'},
            { title: 'Exteriors', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'car_colors-create',
        component: () => import('@pages/setting/car_colors/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_colors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Exteriors', url: { name: 'car_colors' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'car_colors-show',
        component: () => import('@pages/setting/car_colors/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_colors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Exteriors', url: { name: 'car_colors' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Interior */
  {
    path: '/setting/interiors',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'interiors',
        component: () => import('@pages/setting/interior/Index.vue'),
        meta: {
          auth: true,
          parent: 'interiors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Items Product'},
            { title: 'Interiors', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'interiors-create',
        component: () => import('@pages/setting/interior/Create.vue'),
        meta: {
          auth: true,
          parent: 'interiors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Interiors', url: { name: 'interiors' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'interiors-show',
        component: () => import('@pages/setting/interior/Create.vue'),
        meta: {
          auth: true,
          parent: 'interiors',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Interiors', url: { name: 'interiors' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Car Type */
  {
    path: '/setting/car_types',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'car_types',
        component: () => import('@pages/setting/car_types/Index.vue'),
        meta: {
          auth: true,
          parent: 'car_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Items Product'},
            { title: 'Car Types', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'car_types-create',
        component: () => import('@pages/setting/car_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Types', url: { name: 'car_types' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'car_types-show',
        component: () => import('@pages/setting/car_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Types', url: { name: 'car_types' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Car Spareparts */
  {
    path: '/setting/car_spareparts',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'car_spareparts',
        component: () => import('@pages/setting/car_spareparts/Index.vue'),
        meta: {
          auth: true,
          parent: 'car_spareparts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Items Product'},
            { title: 'Car Sparepart', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'car_spareparts-create',
        component: () => import('@pages/setting/car_spareparts/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_spareparts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Sparepart', url: { name: 'car_spareparts' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'car_spareparts-show',
        component: () => import('@pages/setting/car_spareparts/Create.vue'),
        meta: {
          auth: true,
          parent: 'car_spareparts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Car Sparepart', url: { name: 'car_spareparts' } },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  /* Transaction Type */
  {
    path: '/setting/transaction_types',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'transaction_types',
        component: () => import('@pages/setting/transaction_types/Index.vue'),
        meta: {
          auth: true,
          parent: 'transaction_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Transaction Types', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'transaction_types-create',
        component: () => import('@pages/setting/transaction_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'transaction_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Transaction Types', url: { name: 'transaction_types' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'transaction_types-show',
        component: () => import('@pages/setting/transaction_types/Create.vue'),
        meta: {
          auth: true,
          parent: 'transaction_types',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Transaction Types', url: { name: 'transaction_types' } },
            { title: 'Edit', active: true }
          ],
        }
      },
    ]
  },
  /* Company Numbering */
  {
    path: '/setting/company_numberings/:company_id/:transaction_type_id',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'company_numberings',
        component: () => import('@pages/setting/company_numberings/Index.vue'),
        meta: {
          auth: true,
          parent: 'company_numberings',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Edit' },
            { title: 'Company Numberings', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'company_numberings-create',
        component: () => import('@pages/setting/company_numberings/Create.vue'),
        meta: {
          auth: true,
          parent: 'company_numberings',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Edit' },
            { title: 'Company Numberings', url: { name: 'company_numberings' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'company_numberings-show',
        component: () => import('@pages/setting/company_numberings/Create.vue'),
        meta: {
          auth: true,
          parent: 'company_numberings',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Companies', url: { name: 'companies' } },
            { title: 'Edit' },
            { title: 'Company Numberings', url: { name: 'company_numberings' } },
            { title: 'Edit', active: true }
          ],
        }
      },
    ]
  },
  /* Role */
  {
    path: '/setting/roles',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'roles',
        component: () => import('@pages/setting/roles/Index.vue'),
        meta: {
          auth: true,
          parent: 'roles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Roles', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'roles-create',
        component: () => import('@pages/setting/roles/Create.vue'),
        meta: {
          auth: true,
          parent: 'roles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Roles', url: { name: 'roles' } },
            { title: 'Add New', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'roles-edit',
        component: () => import('@pages/setting/roles/Create.vue'),
        meta: {
          auth: true,
          parent: 'roles',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Setting'},
            { title: 'Roles', url: { name: 'roles' } },
            { title: 'Edit', active: true }
          ],
        }
      },
    ]
  },
]