export default [
  {
    path: '/marketing',
    redirect: {name: 'price_lists'}
  },
  {
    path: '/marketing/price_lists',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'price_lists',
        component: () => import('@pages/marketing/price_lists/Index.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'price_lists-create',
        component: () => import('@pages/marketing/price_lists/Create.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', url: {name: 'price_lists'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'price_lists-edit',
        component: () => import('@pages/marketing/price_lists/Create.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', url: {name: 'price_lists'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'price_lists-show',
        component: () => import('@pages/marketing/price_lists/Show.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', url: {name: 'price_lists'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/add-cost',
        name: 'price_lists-add-cost',
        component: () => import('@pages/marketing/price_lists/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', url: {name: 'price_lists'} },
            { title: 'Add Cost', active: true }
          ],
        }
      },
      {
        path: ':id/edit-cost/:cost_id',
        name: 'price_lists-edit-cost',
        component: () => import('@pages/marketing/price_lists/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'price_lists',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Price List', url: {name: 'price_lists'} },
            { title: 'Edit Cost', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/marketing/contracts',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'contracts',
        component: () => import('@pages/marketing/contracts/Index.vue'),
        meta: {
          auth: true,
          parent: 'contracts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Contract', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'contracts-create',
        component: () => import('@pages/marketing/contracts/Create.vue'),
        meta: {
          auth: true,
          parent: 'contracts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Contract', url: {name: 'contracts'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'contracts-edit',
        component: () => import('@pages/marketing/contracts/Create.vue'),
        meta: {
          auth: true,
          parent: 'contracts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Contract', url: {name: 'contracts'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'contracts-show',
        component: () => import('@pages/marketing/contracts/Show.vue'),
        meta: {
          auth: true,
          parent: 'contracts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Marketing'},
            { title: 'Contract', url: {name: 'contracts'} },
            { title: 'Detail', active: true }
          ],
        },
      },
      {
        path: ':contract_id/service',
            name: 'contracts-create-pricelist',
            component: () => import('@pages/marketing/price_lists/Create.vue'),
            meta: {
              auth: true,
              parent: 'contracts-show',
              breadcrumb: [
                { title: 'Home', url: '/' },
                { title: 'Marketing'},
                { title: 'Contract', url: {name: 'contracts'} },
                { title: 'Detail' },
                { title: 'Add Price List', active: true}
              ],
            }
      }
    ]
  }
]