import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get(`setting/application`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store(store, payload) {
    try {
      let slug = 'general'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeEmail(store, payload) {
    try {
      let slug = 'email'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeGps(store, payload) {
    try {
      let slug = 'gps'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show({commit}, id) {
    try {
      let data  = await axios.get(`setting/application/${id}`)
      commit("ASSIGN_DATA", data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeEmailTemplateJobOrder(store, payload) {
    try {
      let slug = 'job_order'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeEmailTemplateShipment(store, payload) {
    try {
      let slug = 'shipment'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeEmailTemplateInvoice(store, payload) {
    try {
      let slug = 'invoice'
      let data  = await axios.post(`setting/application/` + slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async storeChannel(store, payload) {
    try {
      let data  = await axios.post(`setting/application/store_channel/` + payload.slug, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async showChannel({commit}, slug) {
    try {
      let data  = await axios.get(`setting/application/show_channel/${slug}`)
      commit("ASSIGN_DATA", data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}