export default [
  {
    path: '/contact',
    redirect: {name: 'contacts'}
  },
  {
    path: '/contact/all_contacts',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'contacts',
        component: () => import('@pages/contact/contacts/Index.vue'),
        meta: {
          auth: true,
          parent: 'contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Contact'},
            { title: 'All Contact', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'contacts-create',
        component: () => import('@pages/contact/contacts/Create.vue'),
        meta: {
          auth: true,
          parent: 'contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Contact'},
            { title: 'All Contact', url: {name: 'contacts'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'contacts-show',
        component: () => import('@pages/contact/contacts/Show.vue'),
        meta: {
          auth: true,
          parent: 'contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Contact'},
            { title: 'All Contact', url: {name: 'contacts'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'contacts-edit',
        component: () => import('@pages/contact/contacts/Create.vue'),
        meta: {
          auth: true,
          parent: 'contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Contact'},
            { title: 'All Contact', url: {name: 'contacts'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id/add-address',
        name: 'contacts-add-address',
        component: () => import('@pages/contact/contacts/AddAddress.vue'),
        meta: {
          auth: true,
          parent: 'contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Contact'},
            { title: 'All Contact', url: {name: 'contacts'} },
            { title: 'Add Address', active: true }
          ],
        }
      },
    ]
  }
]