export default [
    {
      path: '/driver',
      redirect: {name: 'driver_absences'}
    },
    {
      path: '/driver/driver_absences',
      component: { template: '<router-view />' },
      children: [
        {
          path: '',
          name: 'driver_absences',
          component: () => import('@pages/driver/driver_absences/Index.vue'),
          meta: {
            auth: true,
            parent: 'driver_absences',
            breadcrumb: [
              { title: 'Home', url: '/' },
              { title: 'Driver'},
              { title: 'Driver Absences', active: true }
            ],
          }
        },
      ]
    },
    {
    path: '/driver/all_drivers',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'driver_contacts',
        component: () => import('@pages/contact/contacts/Index.vue'),
        meta: {
          auth: true,
          parent: 'driver_contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Driver'},
            { title: 'All Driver', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'driver_contacts-create',
        component: () => import('@pages/contact/contacts/Create.vue'),
        meta: {
          auth: true,
          parent: 'driver_contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Driver'},
            { title: 'All Driver', url: {name: 'driver_contacts'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'driver_contacts-show',
        component: () => import('@pages/contact/contacts/Show.vue'),
        meta: {
          auth: true,
          parent: 'driver_contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Driver'},
            { title: 'All Driver', url: {name: 'driver_contacts'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'driver_contacts-edit',
        component: () => import('@pages/contact/contacts/Create.vue'),
        meta: {
          auth: true,
          parent: 'driver_contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Driver'},
            { title: 'All Driver', url: {name: 'driver_contacts'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id/add-address',
        name: 'driver_contacts-add-address',
        component: () => import('@pages/contact/contacts/AddAddress.vue'),
        meta: {
          auth: true,
          parent: 'driver_contacts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Driver'},
            { title: 'All Driver', url: {name: 'driver_contacts'} },
            { title: 'Add Address', active: true }
          ],
        }
      },
    ]
  }
  ]