import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get(`marketing/contracts`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store(store, payload) {
    try {
      let { data } = await axios.post(`marketing/contracts`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async get_costs(store, id) {
    try {
      let { data } = await axios.get(`marketing/contracts/${id}/costs`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async delete_costs(store, id) {
    try {
      let { data } = await axios.delete(`marketing/contracts/delete_costs/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async store_costs(store, params) {
    try {
      let { data } = await axios.post(`marketing/contracts/store_costs`, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update_costs(store, params) {
    try {
      let { data } = await axios.put(`marketing/contracts/costs/` + params.id, params)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show({commit}, id) {
    try {
      let { data } = await axios.get(`marketing/contracts/${id}`)
      commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async showCost({commit}, cost_id) {
    try {
      let { data } = await axios.get(`marketing/contracts/costs/${cost_id}`)
      commit("ASSIGN_DATA", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async update(store, payload) {
    try {
      let { data } = await axios.put(`marketing/contracts/${payload.id}`, payload)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async destroy(store, id) {
    try {
      let { data } = await axios.delete(`marketing/contracts/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}