import axios from '@/axios'
const state = {
  cities: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_CITIES(state, data) {
    state.cities = data
  },
}
const actions = {
  async cities({ commit }, payload) {
    try {
      let { data } = await axios.get(`setting/cities`, {params: payload})
      commit("ASSIGN_CITIES", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async show_cities(store, id) {
    try {
      let { data } = await axios.get(`setting/cities/${id}`)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}