import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  }
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  }
}
const actions = {
  async index({ commit }, payload) {
    try {
      let { data } = await axios.get(`operational/reports/driver_performances`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  }
}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}