export default [
  {
    path: '/operational',
    redirect: {name: 'job_orders'}
  },
  {
    path: '/operational/job_orders',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'job_orders',
        component: () => import('@pages/operational/job_orders/Index.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'job_orders-create',
        component: () => import('@pages/operational/job_orders/Create.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'job_orders-edit',
        component: () => import('@pages/operational/job_orders/Create.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'job_orders-show',
        component: () => import('@pages/operational/job_orders/Show.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':job_order_id/files/create',
        name: 'job_orders-add-files',
        component: () => import('@pages/operational/job_orders/sections/FilesCreate.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Add Files', active: true }
          ],
        }
      },
      {
        path: ':job_order_id/files/:id/edit',
        name: 'job_orders-edit-files',
        component: () => import('@pages/operational/job_orders/sections/FilesCreate.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Edit Files', active: true }
          ],
        }
      },
      {
        path: ':job_order_id/item',
        name: 'job_orders-add-item',
        component: () => import('@pages/operational/job_orders/Create.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Add Item', active: true }
          ],
        }
      },
      {
        path: ':job_order_id/item/:id/edit',
        name: 'job_orders-edit-item',
        component: () => import('@pages/operational/job_orders/Create.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Edit Item', active: true }
          ],
        }
      },
      {
        path: ':id/add-cost',
        name: 'job_orders-add-cost',
        component: () => import('@pages/operational/job_orders/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Add Job Order Cost', active: true }
          ],
        }
      },
      {
        path: ':id/edit-cost/:cost_id',
        name: 'job_orders-edit-cost',
        component: () => import('@pages/operational/job_orders/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Edit Job Order Cost', active: true }
          ],
        }
      },
      {
        path: 'import',
        name: 'job_orders-import',
        component: () => import('@pages/operational/job_orders/Import.vue'),
        meta: {
          auth: true,
          parent: 'job_orders',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Job Order', url: {name: 'job_orders'} },
            { title: 'Import Job Order', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/operational/manifests',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'manifests',
        component: () => import('@pages/operational/manifests/Index.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'manifests-create',
        component: () => import('@pages/operational/manifests/Create.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', url: {name: 'manifests'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'manifests-edit',
        component: () => import('@pages/operational/manifests/Create.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', url: {name: 'manifests'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'manifests-show',
        component: () => import('@pages/operational/manifests/Show.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', url: {name: 'manifests'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/add-cost',
        name: 'manifests-add-cost',
        component: () => import('@pages/operational/manifests/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', url: {name: 'manifests'} },
            { title: 'Add Manifest Cost', active: true }
          ],
        }
      },
      {
        path: ':id/edit-cost/:cost_id',
        name: 'manifests-edit-cost',
        component: () => import('@pages/operational/manifests/AddCost.vue'),
        meta: {
          auth: true,
          parent: 'manifests',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Packing Lists', url: {name: 'manifests'} },
            { title: 'Edit Manifest Cost', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/operational/shipments',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'shipments',
        component: () => import('@pages/operational/shipments/Index.vue'),
        meta: {
          auth: true,
          parent: 'shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Shipments', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'shipments-create',
        component: () => import('@pages/operational/shipments/Create.vue'),
        meta: {
          auth: true,
          parent: 'shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Shipments', url: {name: 'shipments'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'shipments-edit',
        component: () => import('@pages/operational/shipments/Create.vue'),
        meta: {
          auth: true,
          parent: 'shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Shipments', url: {name: 'shipments'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'shipments-show',
        component: () => import('@pages/operational/shipments/Show.vue'),
        meta: {
          auth: true,
          parent: 'shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Shipments', url: {name: 'shipments'} },
            { title: 'Detail', active: true }
          ],
        }
      },
      {
        path: ':id/upload',
        name: 'shipments-upload',
        component: () => import('@pages/operational/shipments/Upload.vue'),
        meta: {
          auth: true,
          parent: 'shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Shipments', url: {name: 'shipments'} },
            { title: 'Upload', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/invoices',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'invoices',
        component: () => import('@pages/operational/invoices/Index.vue'),
        meta: {
          auth: true,
          parent: 'invoices',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Invoice', active: true }
          ],
        }
      },
      {
        path: 'create',
        name: 'invoices-create',
        component: () => import('@pages/operational/invoices/Create.vue'),
        meta: {
          auth: true,
          parent: 'invoices',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Invoice', url: {name: 'invoices'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: ':id/edit',
        name: 'invoices-edit',
        component: () => import('@pages/operational/invoices/Create.vue'),
        meta: {
          auth: true,
          parent: 'invoices',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Invoice', url: {name: 'invoices'} },
            { title: 'Edit', active: true }
          ],
        }
      },
      {
        path: ':id',
        name: 'invoices-show',
        component: () => import('@pages/operational/invoices/Show.vue'),
        meta: {
          auth: true,
          parent: 'invoices',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Invoice', url: {name: 'invoices'} },
            { title: 'Detail', active: true }
          ],
        }
      },
    ]
  },
  {
    path: '/operational/customer-payments',
    component: { template: '<router-view />' },
    children: [
      {
        path: 'receivables',
        name: 'receivables',
        component: () => import('@pages/operational/customer_payments/receivables/Index.vue'),
        meta: {
          auth: true,
          parent: 'receivables',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Customer Payments'},
            { title: 'Receivables', active: true }
          ],
        }
      },
      {
        path: 'bills',
        name: 'bills',
        component: () => import('@pages/operational/customer_payments/bills/Index.vue'),
        meta: {
          auth: true,
          parent: 'bills',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Customer Payments'},
            { title: 'Bills', active: true }
          ],
        }
      },
      {
        path: 'bills/create',
        name: 'bills-create',
        component: () => import('@pages/operational/customer_payments/bills/Create.vue'),
        meta: {
          auth: true,
          parent: 'bills',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Customer Payments'},
            { title: 'Bills', url: {name: 'bills'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: 'bills/:id',
        name: 'bills-show',
        component: () => import('@pages/operational/customer_payments/bills/Show.vue'),
        meta: {
          auth: true,
          parent: 'bills',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Customer Payments'},
            { title: 'Bills', url: {name: 'bills'} },
            { title: 'Show', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/vendor-payments',
    component: { template: '<router-view />' },
    children: [
      {
        path: 'payables',
        name: 'payables',
        component: () => import('@pages/operational/vendor_payments/payables/Index.vue'),
        meta: {
          auth: true,
          parent: 'payables',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Vendor Payments'},
            { title: 'Payables', active: true }
          ],
        }
      },
      {
        path: 'debts',
        name: 'debts',
        component: () => import('@pages/operational/vendor_payments/debts/Index.vue'),
        meta: {
          auth: true,
          parent: 'debts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Vendor Payments'},
            { title: 'Debts', active: true }
          ],
        }
      },
      {
        path: 'debts/create',
        name: 'debts-create',
        component: () => import('@pages/operational/vendor_payments/debts/Create.vue'),
        meta: {
          auth: true,
          parent: 'debts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Vendor Payments'},
            { title: 'Debts', url: {name: 'debts'} },
            { title: 'Create', active: true }
          ],
        }
      },
      {
        path: 'debts/:id',
        name: 'debts-show',
        component: () => import('@pages/operational/vendor_payments/debts/Show.vue'),
        meta: {
          auth: true,
          parent: 'debts',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Vendor Payments'},
            { title: 'Debts', url: {name: 'debts'} },
            { title: 'Show', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/map_shipments',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'map_shipments',
        component: () => import('@pages/operational/map_shipments/Index.vue'),
        meta: {
          auth: true,
          parent: 'map_shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Map Shipments', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/reports',
    component: { template: '<router-view />' },
    children: [
      {
        path: 'car-shipment',
        name: 'car-shipment',
        component: () => import('@pages/operational/reports/car-shipment/Index.vue'),
        meta: {
          auth: true,
          parent: 'car-shipment',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Reports'},
            { title: 'Car Shipment', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/reports/sparepart_shipments',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'sparepart_shipments',
        component: () => import('@pages/operational/reports/sparepart_shipment/Index.vue'),
        meta: {
          auth: true,
          parent: 'sparepart_shipments',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Reports' },
            { title: 'Sparepart Shipments', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/reports/drivers',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'drivers',
        component: () => import('@pages/operational/reports/drivers/Index.vue'),
        meta: {
          auth: true,
          parent: 'drivers',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Reports' },
            { title: 'Drivers', active: true }
          ],
        }
      }
    ]
  },
  {
    path: '/operational/reports/driver_performances',
    component: { template: '<router-view />' },
    children: [
      {
        path: '',
        name: 'driver_performances',
        component: () => import('@pages/operational/reports/driver_performance/Index.vue'),
        meta: {
          auth: true,
          parent: 'driver_performances',
          breadcrumb: [
            { title: 'Home', url: '/' },
            { title: 'Operational'},
            { title: 'Reports' },
            { title: 'Driver Performances', active: true }
          ],
        }
      }
    ]
  }
]