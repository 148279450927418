/*=========================================================================================
  File Name: router.js
  Description: Routes for vue-router. Lazy loading is enabled.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
import axios from './axios'
import store from './store/store'

/* ROUTER SECTION */
import setting from './router_sections/setting'
import marketing from './router_sections/marketing'
import contact from './router_sections/contact'
import operational from './router_sections/operational'
import driver from './router_sections/driver'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    scrollBehavior () {
        return { x: 0, y: 0 }
    },
    routes: [

        {
    // =============================================================================
    // MAIN LAYOUT ROUTES
    // =============================================================================
            path: '',
            component: () => import('./layouts/main/Main.vue'),
            children: [
        // =============================================================================
        // Theme Routes
        // =============================================================================
              {
                path: '/',
                name: 'home',
                component: () => import('./views/Dashboard.vue'),
                meta: {
                  auth:true
                }
              },
              ...setting,
              ...marketing,
              ...contact,
              ...operational,
              ...driver
            ],
        },
    // =============================================================================
    // FULL PAGE LAYOUTS
    // =============================================================================
        {
            path: '',
            component: () => import('@/layouts/full-page/FullPage.vue'),
            children: [
        // =============================================================================
        // PAGES
        // =============================================================================
              {
                path: '/login',
                name: 'login',
                component: () => import('@/views/pages/Login.vue')
              },
              {
                path: '/forgot-password',
                name: 'forgot-password',
                meta: {
                  auth:false
                },
                component: () => import('@/views/pages/ForgotPassword.vue')
              },
              {
                path: '/change-password/:slug',
                name: 'change-password',
                meta: {
                  auth:false
                },
                component: () => import('@/views/pages/ChangePassword.vue')
              },
              {
                path: '/pages/error-404',
                name: 'page-error-404',
                component: () => import('@/views/pages/Error404.vue')
              },
            ]
        },
        // Redirect to 404 page, if no match found
        {
            path: '*',
            redirect: '/pages/error-404'
        }
    ],
})

router.beforeEach(async (to, from, next) => {
  // Remove initial loading
  // eslint-disable-next-line no-undef
  const cookies = $cookies.get(store.state.cookieBearerKey)
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) appLoading.style.display = "none";
  axios.defaults.headers.common['Authorization'] = cookies
  
  if (to.meta.auth) {
    if (!cookies) return next({ name: 'login' })
    else return next()
  } else {
    return next()
  }
})

export default router
