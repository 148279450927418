import axios from '@/axios'
const state = {
  rows: {
    data: [],
    last_page: 1
  },
  data:{}
}
const getters = {}
const mutations = {
  ASSIGN_INDEX(state, data) {
    state.rows = data
  },
  ASSIGN_DATA(state, data) {
    state.data = data
  }
}

const actions = {
  async report({ commit }, payload) {
    try {
      let { data } = await axios.get(`report`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async unit_delivered_complete_dealer({ commit }, payload) {
    try {
      let { data } = await axios.get(`report/unit-delivered-complete-dealer`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },
  async unit_delivered_complete_driver({ commit }, payload) {
    try {
      let { data } = await axios.get(`report/unit-delivered-complete-driver`, {params: payload})
      commit("ASSIGN_INDEX", data.data)
      return Promise.resolve(data)
    } catch (error) {
      return Promise.reject(error.response)
    }
  },

}
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}